.header {
  display: flex;
  padding-block: 2rem;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  transition: box-shadow 0.8s ease;
}

.scrolled {
  /* box-shadow: 0px 5px 10px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);  */
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.header-content {
  max-width: 90rem;
  width: 100%;
  margin: auto;
  display: flex;
  padding-inline: 1.5rem;
  justify-content: space-between;
  align-items: center;
}

.carousel-container {
  max-width: 90rem;
  width: 100%;
  margin-bottom: 7.5rem;
  position: relative;
}

.carousel {
  background-color: var(--gray);
  max-height: 100%;
  max-width: 100%;
  flex-shrink: 0;
}

.carousel-item img {
  max-height: 100%;
  max-width: 100%;
  object-fit: conatin;
  width: 100%;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.partners {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.partners-title {
  /**/
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  color: var(--primary-color);
  margin-bottom: 3.5rem;
}

.why-choose-us,
.why-sentosa-link {
  margin-bottom: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 1.5rem;
}

.content-title {
  margin-bottom: 2rem;
  font-weight: 800;
  font-size: 2.375rem;
  line-height: 120%;
  color: var(--primary-color-dark);
  text-align: center;
}

.content-text {
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 180%;
  color: var(--text-color);
}

.partners-in-collaboration {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  box-sizing: border-box;
  padding-inline: 1.5rem;
  margin-bottom: 7.5rem;
}

.partner-logo {
  display: flex;
  width: 7.5rem;
  height: 7.5rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.partner-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.carousel-container {
  max-width: 100%;
  width: 100%;
  margin-bottom: 7.5rem;
  position: relative;
}

.carousel-item {
  width: 100%;
  padding-top: 38.89%;
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.why-sentosa-link-image {
  border-radius: 4px;
}

.why-sentosa-link-image img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.why-sentosa-link-content {
  /* margin: 19.2px 0 0 0; */
  /* display: flex; */
  box-sizing: border-box;
  max-width: 36.25rem;
}

.why-sentosa-link .content-title {
  margin-bottom: 5rem;
}

.value-proposition {
  margin-bottom: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 1.5rem;
}

.value-proposition .content-title {
  margin-bottom: 1rem;
  font-weight: 800;
  font-size: 2.375rem;
  line-height: 120%;
  color: var(--primary-color-dark);
  text-align: center;
}

.value-proposition p {
  color: var(--Grayscale-Body-Text-Color, #495057);
  text-align: center;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 5rem;
}

.value-proposition .content .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.card .title {
  color: var(--primary-color-dark);
  text-align: center;
  /**/
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1rem;
  padding-inline: 2.22rem;
}

.card .text {
  color: var(--text-color);
  text-align: center;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.value-proposition .content .card .image1,
.value-proposition .content .card .image2,
.value-proposition .content .card .image3 {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-clip: content-box;
}

.value-proposition .content .card .image1 {
  width: 210px;
  height: 210px;
}

.value-proposition .content .card .image2 {
  width: 184.013px;
  height: 210px;
}

.value-proposition .content .card .image3 {
  width: 145px;
  height: 210px;
}

.value-proposition .content .card .image1 {
  background-image: url("../../assets/images/vp1.png");
}

.value-proposition .content .card .image2 {
  background-image: url("../../assets/images/vp2.svg");
}

.value-proposition .content .card .image3 {
  background-image: url("../../assets/images/vp3.svg");
}

@media screen and (min-width: 1016px) {
  .why-choose-us,
  .partners-in-collaboration,
  .why-sentosa-link,
  .value-proposition {
    padding-inline: 8rem;
  }
}

@media screen and (max-width: 600px) {
  .why-choose-us,
  .partners-in-collaboration,
  .why-sentosa-link,
  .value-proposition {
    padding-inline: 1rem;
  }

  .content-title {
    font-size: 1.75rem !important;
  }

  .content-text,
  p,
  .text {
    font-size: 0.875rem !important;
  }
}
