.leadership {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 7.5rem;
  width: 100%;
}

.leadership-title {
  margin: 0 0 3rem 0;
  font-weight: var(--heading-3-font-weight, 800);
  font-size: 2.375rem; /* 38px to rem */
  line-height: var(--heading-3-line-height, 1.2);
  color: var(--primary-color-primary-dark, #1c3578);
  text-align: center;
}

.leadership-content {
  padding-inline: 1.5rem;
  width: 100%;
}

.card {
  position: relative; /* To position the overlay within the card */
  display: flex;
  width: 15.5rem;
  height: 17.5rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.25rem;
  background: #d9d9d9;
  overflow: hidden; /* Ensures the overlay doesn't spill out */
  transition: transform 0.3s ease; /* Smooth transition for hover effects */
}

.card:hover {
  transform: translateY(-0.25rem); /* Slight lift on hover */
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  padding-block: 0.8rem;
  box-sizing: border-box;
}

.card:hover .overlay {
  opacity: 0.7;
  transform: translateY(0);
}

.surname,
.jobposition {
  font-size: 1rem;
  font-weight: bold;
}

.leadership-row {
  --bs-gutter-x: 1.88rem;
  --bs-gutter-y: 1.88rem;
  padding-top: 3.5rem;
}

.organization-structure {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-inline: 1.5rem;
  box-sizing: border-box;
}

.organization-title {
  margin: 0 0 3.5rem 0; /* 56px and 51px to rem */
  display: inline-block;
  /**/
  font-weight: var(--heading-3-font-weight, 800);
  font-size: 2.375rem; /* 38px to rem */
  line-height: var(--heading-3-line-height, 1.2);
  color: var(--primary-color-primary-dark, #1c3578);
  text-align: center;
}

.organization-description {
  /**/
  font-weight: var(--body-font-weight, 400);
  font-size: 1rem; /* 16px to rem */
  line-height: 1.8;
  color: var(--text-color);
  word-break: break-words;
  margin-bottom: 2rem; /* Adjust spacing as needed */
}

.organization-wrapper {
  max-width: 73.188rem;
  max-height: 90rem;
  margin-bottom: 7.5rem;
  margin-inline: auto;
}

.organization-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto; /* Center the content */
}

.team-member1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 166px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.team-member1:hover {
  opacity: 87%;
}

.team-member-image1 {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: 2px solid var(--secondary-color);
  background-image: url("../../assets/images/AvatarPlaceholderBlue.png");
  background-position: center;
  background-size: calc(100% - 15px);
  background-repeat: no-repeat;
  background-clip: content-box;
  box-sizing: border-box;
  margin-bottom: 1rem;
  transition: all 0.4s ease;
}

.team-member-info1 {
  text-align: center;
}

.team-member-name1 {
  /**/
  font-weight: var(--heading-6-font-weight, 500);
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.2;
  color: var(--grayscale-white, #ffffff);
  background-color: var(--secondary-color); /* Adjust color as needed */
  padding: 4px 0;
  border-radius: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding-inline: 1.25rem;
}

.team-member-position1 {
  /**/
  font-weight: var(--body-font-weight, 400);
  font-size: 0.875rem; /* Adjust font size as needed */
  line-height: 1.2;
  color: var(--text-color);
}

.team-member-row1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-member-row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: -5.2rem;
}

.team-member-row2-col1,
.team-member-row2-col2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 532.38px;
}

.team-member2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 166px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.team-member2:hover {
  opacity: 87%;
}

.team-member-image2 {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: 2px solid var(--orange);
  background-image: url("../../assets/images/AvatarPlaceholderOrange.png");
  background-position: center;
  background-size: calc(100% - 15px);
  background-repeat: no-repeat;
  background-clip: content-box;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: white;
  transition: all 0.4s ease;
}

.team-member-info2 {
  text-align: center;
}

.team-member-name2 {
  /**/
  font-weight: var(--heading-6-font-weight, 500);
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.2;
  color: var(--grayscale-white, #ffffff);
  background-color: var(--orange); /* Adjust color as needed */
  padding: 4px 0;
  border-radius: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding-inline: 1.25rem;
}

.team-member-position2 {
  /**/
  font-weight: var(--body-font-weight, 400);
  font-size: 0.875rem; /* Adjust font size as needed */
  line-height: 1.2;
  color: var(--text-color);
}

.team-member-row3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
}
.team-member-row3-col1,
.team-member-row3-col2 {
  display: flex;
  gap: 1.5rem;
  position: relative;
  padding-top: 3.5rem;
}

.team-member3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 166px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.team-member3:hover {
  opacity: 87%;
}

.team-member-image3 {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: 2px solid var(--green);
  background-image: url("../../assets/images/AvatarPlaceholderGreen.png");
  background-position: center;
  background-size: calc(100% - 15px);
  background-repeat: no-repeat;
  background-clip: content-box;
  box-sizing: border-box;
  margin-bottom: 1rem;
  transition: all 0.4s ease;
}

.team-member-info3 {
  text-align: center;
}

.team-member-name3 {
  /**/
  font-weight: var(--heading-6-font-weight, 500);
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.2;
  color: var(--grayscale-white, #ffffff);
  background-color: var(--green); /* Adjust color as needed */
  padding: 4px 0;
  border-radius: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding-inline: 1.25rem;
}

.team-member-position3 {
  /**/
  font-weight: var(--body-font-weight, 400);
  font-size: 0.875rem; /* Adjust font size as needed */
  line-height: 1.2;
  color: var(--text-color);
}

.team-member-info1,
.team-member-info2,
.team-member-info3 {
  line-height: normal;
}

.line-left {
  position: absolute;
  right: 9rem;
  top: 5rem;
}

.line-right {
  position: absolute;
  left: 9rem;
  top: 5rem;
}

.tree-left {
  position: absolute;
  top: 0;
  left: 5rem;
}

.tree-right {
  position: absolute;
  top: 0;
  right: 5rem;
}

.line-left,
.line-right,
.tree-left,
.tree-right,
.line-left-mobile,
.line-right-mobile,
.tree-mobile {
  transition: opacity 0.4s ease;
}

.line-left-mobile,
.line-right-mobile,
.tree-mobile {
  opacity: 0;
  display: none;
}

@media screen and (max-width: 1157px) {
  .line-left,
  .line-right,
  .tree-left,
  .tree-right {
    opacity: 0;
    display: none;
  }

  .line-left-mobile,
  .line-right-mobile,
  .tree-mobile {
    opacity: 1;
    display: block;
  }
}

@media screen and (max-width: 1157px) {
  .team-member1,
  .team-member2,
  .team-member3 {
    width: 7.8125rem;
  }
  .team-member-row3-col1,
  .team-member-row3-col2 {
    gap: 0rem;
    padding-top: 0;
  }
  .team-member-row3-col1 {
    padding-top: 2.49rem;
  }
  .team-member-row3 {
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
  }
  .team-member-row2 {
    justify-content: center;
    margin: 0;
    gap: 7.5rem;
  }
  .team-member-row2-col1 {
    justify-content: flex-end;
  }
  .team-member-row2-col2 {
    justify-content: flex-start;
  }
  .team-member-image1,
  .team-member-image2,
  .team-member-image3 {
    width: 4.25rem;
    height: 4.25rem;
  }
  .team-member-name1,
  .team-member-name2,
  .team-member-name3 {
    font-size: 0.75rem;
    padding: 0.25rem 0.62rem;
    margin-bottom: 0.12rem;
  }

  .team-member-position1,
  .team-member-position2,
  .team-member-position3 {
    font-size: 0.75rem;
  }
  .line-left-mobile {
    top: 7.1rem;
    position: absolute;
    right: 7.2rem;
    flex-shrink: 0;
  }
  .line-right-mobile {
    top: 7.1rem;
    position: absolute;
    left: 7.2rem;
    flex-shrink: 0;
  }
  .tree-mobile {
    position: absolute;
    top: -0.2rem;
    right: 2.1rem;
  }
}

@media screen and (min-width: 1016px) {
  .leadership,
  .organization-structure {
    padding-inline: 8rem;
  }
}
@media screen and (max-width: 600px) {
  .leadership,
  .organization-structure {
    padding-inline: 1rem;
  }
  .leadership-title,
  .organization-title {
    font-size: 1.75rem;
  }
  .surname,
  .jobposition,
  .organization-description {
    font-size: 0.875rem !important;
  }
}

.card-col {
  display: flex;
  justify-content: center;
}
