.contact-info {
  /* height: 8.4rem; */

  padding-inline: 8rem;
  gap: 3rem;
  padding-block: 1.5rem 8rem;
}
.contact-form-title {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 2.45rem */
  margin-bottom: 2.5rem;
}

.contact-form {
  margin-top: 0;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  margin-bottom: 3.12rem;
}

.contact-socials {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.88rem;
}

.social-icons {
  display: flex;
  width: 2.625rem;
  height: 2.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: rgba(37, 67, 148, 0.05);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Add transition for smooth effect */
}

.social-icons:hover {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3); /* Increase spread, blur, and opacity */
}

/* Media query for screens 1024px and wider */
@media (max-width: 1024px) {
  .contact-info {
    padding-inline: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-info {
    padding-inline: 1rem;
  }
  .contact-details {
    font-size: 0.875rem;
  }
  .contact-form-title {
    font-size: 1.5rem !important;
  }
}
