@keyframes slideUpFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideUp {
  animation: slideUpFadeIn 1s ease-out forwards;
}

.slideUp:nth-child(1) {
  animation-delay: 0.1s;
}

.slideUp:nth-child(2) {
  animation-delay: 0.2s;
}

.slideUp:nth-child(3) {
  animation-delay: 0.3s;
}

.fadeInSection {
  opacity: 0;
  transform: translateY(3vh) scale(0.98);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out, visibility 0.6s;
  transition-delay: var(--fade-delay, 0s);
  will-change: opacity, transform, visibility;
}

.isVisible {
  opacity: 1;
  transform: translateY(0) scale(1);
  visibility: visible;
  width: auto;
  height: auto;
}

@media (max-width: 600px) {
  .slideUp,
  .fadeInSection {
    animation: none;
    opacity: 1;
    transform: none;
    visibility: visible;
    transition: none;
  }
}
