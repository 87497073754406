.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.content-wrapper {
  max-width: 90rem;
  width: 100%;
  margin: auto;
}

.custom-btn {
  transition: background-color 0.3s ease !important; /* Smooth transition */
  background: var(--primary-color) !important;
  padding: 1rem 2rem !important;
  border-radius: 0.25rem !important;
  color: white !important;
  border: none !important;
  font-weight: 600 !important;
  margin-top: 1rem;
}

.custom-btn:hover {
  background-color: #0056b3 !important;
}

.react-multi-carousel-track {
  margin-bottom: 2.5rem;
}
.react-multi-carousel-dot button {
  fill: white;
  stroke-width: 2px;
  stroke: var(--primary-color-dark);
  opacity: 0.2;
}

.react-multi-carousel-dot--active button {
  fill: var(--primary-color);
  background-color: var(--primary-color);
  stroke-width: 2px;
  stroke: var(--primary-color-dark);
  opacity: 100%;
  border-color: var(--primary-color-dark);
}

.nav-tabs,
.nav-tabs:hover {
  border: 0;
}

.nav-tabs .nav-link {
  color: var(--text-color) !important ;
  /**/
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.hover {
  color: var(--primary-color-dark) !important;
  border: 0;
  border-bottom: 2px solid var(--primary-color);
}

.nav-item.button,
.nav-item.active.button {
  border: 0 !important;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0;
}
.nav-tabs .nav-link {
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0.3rem 0;
  /**/
}
.nav-item .nav-link.active {
  border-bottom: 2px solid var(--primary-color) !important;
}
.leadership-row {
  --bs-gutter-x: 1.88rem;
  --bs-gutter-y: 1.88rem;
  padding-top: 3.5rem;
}

.modal-content .modal-header {
  padding-inline: 5rem;
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
  border-bottom: none;
}

.modal-content .modal-body {
  padding-inline: 5rem;
  padding-bottom: 7.5rem;
}

@media (min-width: 576px) {
  .modal.fade .modal-dialog {
    max-width: 67rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.modal-body-image {
  max-width: 20rem;
  max-height: 22.22225rem;
  border-radius: 0.25rem;
}

.modal-body-image img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 600px) {
  .modal-dialog .modal-content .modal-body {
    padding-inline: 0.875rem;
    padding-bottom: 2rem;
  }
  .modal-dialog .modal-content .modal-body h5 {
    font-size: 1rem;
  }
  .modal-dialog .modal-content .modal-body h6,
  .modal-dialog .modal-content .modal-body p {
    font-size: 0.875rem;
  }
  .modal-dialog .modal-content .modal-header {
    padding-inline: 1.5rem;
    padding-top: 2rem;
  }
  .btn,
  .accordion-body,
  .form-label,
  .form-control {
    font-size: 0.875rem !important;
  }
  .nav-tabs .nav-link {
    font-size: 1rem !important;
  }
  .accordion-item > .accordion-header .accordion-button {
    font-size: 1rem !important;
  }
}

.modal-body-title {
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.tab-pane .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}

.me-auto {
  margin-right: 0 !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem !important;
}

@media screen and (max-width: 600px) {
  .carousel-indicators {
    transition: all 0.4s ease;
    bottom: -3rem !important;
  }
}

@media screen and (min-width: 1016px) {
  .navbar .container {
    padding-inline: 8rem !important;
  }
}

@media screen and (max-width: 600px) {
  .navbar .container {
    padding-inline: 1rem !important;
  }
}

.navbar-toggler-icon {
  background-image: url("./assets/icons/navbar-toggle.svg") !important;
}

.navbar-toggler {
  border: transparent !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 3px 0.15rem rgba(37, 67, 148, 0.5) !important;
}

.react-multi-carousel-track .react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-button:not(.collapsed) {
  color: var(--primary-color-dark);
  background-color: unset !important;
  box-shadow: none !important;
}

.accordion-item > .accordion-header .accordion-button {
  color: var(--primary-color-dark);
  /* Heading 5 */
  /**/
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 1.575rem */
  border-radius: 0.3125rem;
}

.accordion-item {
  margin-bottom: 2rem;
  border-radius: 0.3125rem;
  border: 0 !important;
  background-color: var(--gray-bg) !important;
}

.accordion-item > .accordion-header .accordion-button {
  background-color: var(--gray-bg) !important;
  padding: 1.8rem;
}

.accordion-body {
  padding: 0 1.8rem 1.8rem 1.8rem !important;
  color: var(--text-color);
  /* Body */
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 1.8rem */
}

.accordion-button::after {
  flex-shrink: 0;
  margin-left: auto;
  background-image: url("/src/assets/icons/PlusOutlined.svg") !important;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("/src/assets/icons/MinusOutlined.svg") !important;
  transform: rotate(-180deg) !important;
}

.form-control {
  border: transparent;
  background: #f6f6f6 !important;
  color: #a0a3bd !important;
}
.form-label {
  color: #6e7191;
}

input.form-control {
  height: 3rem;
}

.banner-container {
  position: relative;
  width: 100%; /* Responsive width */
  height: auto; /* Height will adjust automatically */
  overflow: hidden; /* Ensures car won't go outside the container */
}

.road-img {
  width: 100%;
  height: auto;
  display: block; /* Road resizes based on screen size */
}

.car-svg {
  position: absolute;
  bottom: 10%; /* Adjust this depending on where the car should be on the road */
  width: 10%; /* Responsive car size using viewport width */
  animation: moveCar 20s linear infinite; /* Adjust speed and repeat */
  z-index: 1;
}

@keyframes moveCar {
  0% {
    transform: translateX(-100%); /* Start from the left */
  }
  100% {
    transform: translateX(100vw); /* Move beyond the right side */
  }
}

.man-svg {
  position: absolute;
  bottom: 18%; /* Adjust this depending on where the car should be on the road */
  width: 10%; /* Responsive car size using viewport width */
  animation: moveCar 13s linear infinite; /* Adjust speed and repeat */
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

.dropdown-item.active::before,
.dropdown-item:active::before {
  width: 100% !important;
}
/* 
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {  
    position: absolute;
    border: none;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
  }
} */
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border: none;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
}
/* .dropdown-item {
  text-align: left !important;
  padding-left: 4rem !important;
  padding-right: 0 !important;
} */

.navbar-nav .dropdown-menu {
  border: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--primary-color) !important;
}

.operation .react-multi-carousel-track {
  margin: 0 !important;
}

.operation .react-multi-carousel-track .react-multi-carousel-item {
  align-items: start;
}

 .gm-style iframe + div {
  border-color: var(--primary-color) !important; 
  border-radius: 20px; 
}
