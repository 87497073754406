/* Footer.module.css */
.container {
  background-color: var(--primary-color-primary-dark, #1c3578);
  color: var(--grayscale-white, #ffffff);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 5rem 1.5rem;
  box-sizing: border-box;
  max-width: 90rem;
  margin-inline: auto;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  /* margin-right: 2rem; */
}

.title {
  /**/
  font-weight: 600;
  font-size: 21px;
  line-height: 1.2;
  margin-bottom: 0.62rem;
}

.text {
  /**/
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 0.625rem;
}

.copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  text-align: center;
  --bs-gutter-y: 0 !important;
  width: 100%;
}

.copyright-text {
  /**/
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
}

@media screen and (min-width: 1016px) {
  .footer {
    padding-inline: 8rem;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding-inline: 1rem;
  }
  .text,
  .copyright-text {
    font-size: 0.875rem !important;
  }
  .section .title {
    font-size: 1rem;
  }
}

.logo {
  width: 14rem;
  height: 2.0625rem;
  margin-bottom: 1.25rem;
}

.section a {
  color: white;
  opacity: 60%;
  text-decoration: none;
  transition: all 0.4s ease;
}
.section a:hover {
  opacity: 100%;
}
.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--primary-color); /* You can adjust the color */
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease-in-out;
  opacity: 0;  width: 2.5rem;
  height: 2.5rem;
}

.visible {
  opacity: 100%;
}


/* .scrollToTop svg {
  width: 5px;
  height: 5px;
} */
