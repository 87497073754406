.header {
  display: flex;
  padding-block: 2rem;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  transition: box-shadow 0.8s ease;
  max-width: 90rem;
}

.scrolled {
  /* box-shadow: 0px 5px 10px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);  */
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.header-content {
  width: 100%;
  margin: auto;
  display: flex;
  padding-inline: 8rem;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  gap: 1.5rem;
}

.navList {
  /* font-family: "Poppins", sans-serif; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  display: flex;
  padding: 0.3125rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  padding-left: 2.5rem;
}

@media (max-width: 991.98px) {
  .navList {
    padding-left: 0;
  }
  .container .navbar-brand {
    padding-top: 1rem;
  }
}

.navList a {
  position: relative;
  padding-block: 0.5rem;
  text-decoration: none;
  color: var(--text-color);
  transition: color 0.3s ease;
  text-align: center;
  /* font-family: "Poppins", sans-serif; */
}

.navList a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px; /* Adjust the thickness of the border */
  background-color: var(--primary-color);
  transition: all 0.5s ease;
  transform: translateX(-50%);
}

.navList a:hover::before,
.navList a:focus::before,
.navList a:active::before,
.navList a.active::before {
  width: 100%;
}

.navList a:hover,
.navList a:focus,
.navList a:active,
.navList a.active {
  color: var(--primary-color); /* Change text color when active */
}

/* a:active,
a:hover {
  color: var(--primary-color);
  transition: all 0.7s;
} */

.basic-navbar-nav .navbar-nav {
  margin-left: 0;
  margin-right: auto;
}

.header-container {
  padding-block: 2rem;
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  transition: box-shadow 0.8s ease;
}

/* Media query for screens below 992px */
@media (max-width: 991.98px) {
  .navList {
    padding-left: 0;
  }

  .navList:first-child {
    padding-top: 1.5rem;
  }

  .container .navbar-brand {
    padding-top: 1rem;
  }
}

@media (max-width: 600px) {
  .logo {
    width: 11rem;
  }
}
