.container {
  margin-bottom: 7.5rem;
  padding-inline: 1.5rem;
}

.title {
  margin-bottom: 1rem;
  font-weight: 800;
  font-size: 2.375rem;
  line-height: 120%;
  color: var(--primary-color-dark);
  text-align: center;
}

.subtitle {
  color: var(--text-color);
  text-align: center;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 5rem;
  max-width: 53.125rem;
  margin-inline: auto;
}

.card {
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 1.87rem;
  max-width: 21.815rem;
}
.card img {
  max-width: 100%;
  max-height: 100%;
}

.card .title {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  text-align: left;
  margin: 0;
  margin-bottom: 0.75rem;
}

.card .text {
  color: var(--text-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 1.8rem */
}

.arrowLeft {
  display: flex;
  padding: 1.25rem 0.9375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  background: rgba(37, 67, 148, 0.07);
  cursor: pointer;
  position: absolute;
  top: 16%;
}

.arrowRight {
  display: flex;
  padding: 1.25rem 0.9375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  background: rgba(37, 67, 148, 0.07);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 16%;
}

.dms-card {
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 1.87rem;
  max-width: 21.815rem;
  align-items: center;
  height: 100%;
}

.dms-card img {
  max-width: 100%;
  max-height: 100%;
}

.dms-card .title {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  text-align: left;
  margin: 0;
  margin-bottom: 0.75rem;
}

.dms-card .text {
  color: var(--text-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 1.8rem */
}

.img-container {
  height: 13.6325rem;
  display: flex;
  align-items: end;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .text,
  .subtitle {
    font-size: 0.875rem !important;
  }
  .title {
    font-size: 1.75rem;
  }
}
