.container {
  margin-bottom: 6.5rem;
}

.image {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    margin-bottom: 3rem;
  }
}
