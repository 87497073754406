.tabsContainer {
  width: 100%;
  margin: 0 auto;
}

.tabs {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(37, 67, 148, 0.05);
  border-radius: 0.625rem 0.625rem 0 0;
  overflow: hidden;
}

.tabs::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  transition: transform 0.5s ease, width 0.5s ease;
  z-index: 0;
}

.tabButton {
  position: relative;
  background: none;
  border: none;
/**/
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
  outline: none;
  transition: color 0.3s;
  display: flex;
  padding: 1.25rem 3.125rem;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Keep text above the background */
  cursor: pointer;
}

.tabButton.active {
  color: #fff;
}

.tabContent {
  display: flex;
  padding: 2.5rem 3.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  background: var(--gray-bg);
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  transition: all 0.5s ease;
}

.title {
  color: var(--primary-color);
/**/
  font-size: 1.75rem;
  font-weight: 700;
}

.text {
  color: var(--text-color);
/**/
  font-size: 1rem;
  font-weight: 400;
}

.indicator {
  position: absolute;
  height: 100%;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  transition: transform 0.5s ease, width 0.5s ease;
  z-index: 0;
}

.tabsContainer {
  width: 100%;
  margin: 0 auto;
}

.tabs {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(37, 67, 148, 0.05);
  border-radius: 0.625rem 0.625rem 0 0;
  overflow: hidden;
}

.tabs::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  transition: transform 0.5s ease, width 0.5s ease;
  z-index: 0;
}

.tabButton {
  position: relative;
  background: none;
  border: none;
/**/
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
  outline: none;
  transition: color 0.3s;
  display: flex;
  padding: 1.25rem 3.125rem;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.tabButton:not(.active):hover {
  color: var(--primary-color);
}

.tabButton.active {
  color: #fff;
}

.tabContent {
  display: flex;
  padding: 2.5rem 3.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  background: var(--gray-bg);
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  transition: all 0.5s ease;
}

.title {
  color: var(--primary-color);
/**/
  font-size: 1.75rem;
  font-weight: 700;
}

.text {
  color: var(--text-color);
/**/
  font-size: 1rem;
  font-weight: 400;
}

.indicator {
  position: absolute;
  height: 100%;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  transition: transform 0.5s ease, width 0.5s ease;
  z-index: 0;
}

.listTitle {
/**/
  font-weight: 600;
}

.listText {
  margin-left: 1rem;
  margin-bottom: 1.8rem;
}

@media (max-width: 768px) {
  .tabButton {
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  .tabContent {
    padding: 2rem 2.5rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .text {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .tabButton {
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
  }

  .tabContent {
    padding: 1.5rem 2rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .text {
    font-size: 0.875rem;
  }
}
