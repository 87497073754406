/* .about-us-container {
  padding-inline: 1.5rem;
} */
.brand-story {
  margin: 1.5rem 0rem 7.5rem 0rem; /* 135px and 120px to rem */
  padding-inline: 1.5rem;
}

.brand-story-content {
  margin-bottom: 5rem;
}

.brand-story-title {
  margin-bottom: 1rem;
  font-weight: 800;
  font-size: 2.375rem;
  line-height: 120%;
  color: var(--primary-color-dark);
  text-align: center;
}

.brand-story p {
  color: var(--text-color);
  text-align: center;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 5rem;
  max-width: 53.125rem;
  margin-inline: auto;
}

.brand-story-content .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.brand-story-content .card .image1,
.brand-story-content .card .image2,
.brand-story-content .card .image3 {
  width: 10.625rem;
  height: 10.625rem;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-clip: content-box;
}

.brand-story-content .card .image1 {
  background-image: url("../../assets/images/bs1.png");
}

.brand-story-content .card .image2 {
  background-image: url("../../assets/images/bs2.png");
}

.brand-story-content .card .image3 {
  background-image: url("../../assets/images/bs3.png");
}

.brand-story-content .card .text {
  color: var(--Grayscale-Body-Text-Color, #495057);
  text-align: center;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 1.8rem */
  max-width: 13.5625rem;
}

.brand-story-text p {
  /**/
  font-weight: 400;
  font-size: 1rem; /* 16px to rem */
  line-height: 1.8;
  color: var(--text-color);
  word-break: break-words;
}

.story {
  color: var(--text-color);
  text-align: justify;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 1.8rem */
}

.story a {
  color: var(#0d6efd);
  text-align: justify;
  /**/
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  text-decoration-line: underline;
}

.mission-vision {
  padding-inline: 1.5rem;
  margin-bottom: 7.5rem;
}

.leadership {
  margin: 0 0 7.5rem 0; /* 120px to rem */
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 1.5rem;
  box-sizing: border-box;
}

.leadership-title {
  margin: 0 0rem 3rem 0; /* 54.4px and 48px to rem */
  /**/
  font-weight: var(--heading-3-font-weight, 800);
  font-size: 2.375rem; /* 38px to rem */
  line-height: var(--heading-3-line-height, 1.2);
  color: var(--primary-color-primary-dark, #1c3578);
  text-align: center;
}

.leadership-description {
  /**/
  font-weight: var(--body-font-weight, 400);
  font-size: 1rem; /* 16px to rem */
  line-height: var(--body-line-height, 1.8);
  color: var(--grayscale-body-text-color, #495057);
  text-align: center;
  margin-bottom: 3rem;
  max-width: 37.375rem;
  margin-inline: auto;
}

.leadership-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.leadership-image {
  width: 12.5rem; /* 200px to rem */
  height: 7.25rem; /* 116px to rem */
  background-image: url("../../assets/images/image\ 11.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1rem; /* 16px to rem */
}

.executives-image {
  width: 12.5rem; /* 200px to rem */
  height: 7.25rem; /* 116px to rem */
  background-image: url("../../assets/images/image\ 12.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1rem; /* 16px to rem */
}

.leadership-name {
  margin: 0 0 1rem 0; /* 16px to rem */
  /**/
  font-weight: var(--heading-5-font-weight, 600);
  font-size: 1.3125rem; /* 21px to rem */
  line-height: var(--heading-5-line-height, 1.2);
  color: var(--grayscale-body-text-color, #495057);
  text-align: center;
}

.leadership .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.88rem;
}

.leadership .card .name,
.leadership .card .detail {
  text-align: center;
}

.leadership .card .name {
  color: var(--text-color);
  text-align: center;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.96875rem */
}

.leadership .card .detail {
  color: var(--text-color);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 1.8rem */
}

.leadership .card .image {
  width: 13.75rem;
  height: 16.25rem;
  background-color: rgba(37, 67, 148, 0.08);
  cursor: pointer;

  background-repeat: no-repeat;
  background-clip: content-box;
  transition: all 0.5s ease;
}

.leadership .card .image:hover {
  transform: translateY(-0.25rem);
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12); */
}

.read-more {
  background-color: var(--primary-color-primary, #254394);
  color: var(--grayscale-white, #ffffff);
  padding: 1rem 0; /* 16px to rem */
  width: 9.5625rem; /* 153px to rem */
  border-radius: 0.25rem; /* 4px to rem */
  display: flex;
  align-items: center;
  justify-content: center;
}

.read-more span {
  /**/
  font-weight: var(--button-font-weight, 600);
  font-size: 1rem; /* 16px to rem */
  line-height: var(--button-line-height, 1.2);
  color: var(--grayscale-white, #ffffff);
}

.how-we-work {
  margin: 0 0rem 7.5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-inline: 1.5rem;
  box-sizing: border-box;
}

.how-we-work-title {
  margin: 0 0 1rem 0;
  /**/
  font-weight: var(--heading-3-font-weight, 800);
  font-size: 38px;
  line-height: var(--heading-3-line-height, 1.2);
  color: var(--primary-color-primary-dark, #1c3578);
}

.how-we-work-content {
  display: flex;
  flex-direction: row;
  width: fit-content;
  box-sizing: border-box;
}

.how-we-work-image {
  /* margin: 0 65.5px 0 0; */

  border-radius: 4px;
}

.how-we-work-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.how-we-work-text {
  max-width: 41.125rem;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
}

.how-we-work-text p {
  text-align: center;
  margin-bottom: 1.62rem;
}

.lorem-ipsum {
  /**/
  font-weight: var(--body-font-weight, 400);
  font-size: 16px;
  line-height: var(--body-line-height, 1.8);
  color: var(--grayscale-body-text-color, #495057);
}

.modal-body {
  display: flex;
  gap: 5rem;
  justify-content: flex-start;
}

.modal-body-image {
  max-width: 20rem;
  max-height: 22.22225rem;
  border-radius: 0.25rem;
}

.modal-body-image img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 600px) {
  .modal-dialog .modal-content .modal-body {
    padding-inline: 1rem !important;
  }
  .modal-dialog .modal-content .modal-header {
    padding-inline: 0 !important;
  }
}

.modal-body-text {
  flex: 1;
}
.carousel-container {
  max-width: 90rem;
  width: 100%;
  margin-bottom: 7.5rem;
  position: relative;
}

.carousel {
  background-color: var(--gray);
  max-height: 100%;
  max-width: 100%;
  flex-shrink: 0;
}

.carousel-item img {
  max-height: 100%;
  max-width: 100%;
  object-fit: conatin;
  width: 100%;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.carousel-container {
  max-width: 100%;
  width: 100%;
  margin-bottom: 7.5rem;
  position: relative;
}

.carousel-item {
  width: 100%;
  padding-top: 38.89%;
  position: relative;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1016px) {
  .brand-story,
  .mission-vision,
  .how-we-work,
  .organization-structure,
  .leadership {
    padding-inline: 8rem;
  }
}

@media screen and (max-width: 600px) {
  .brand-story,
  .mission-vision,
  .how-we-work,
  .organization-structure,
  .leadership {
    padding-inline: 1rem;
  }
  .brand-story-title,
  .leadership-title,
  .organization-title,
  .how-we-work-title {
    font-size: 1.75rem;
  }
  .brand-story p,
  .about-us-text,
  .how-we-work-text,
  .text,
  .story,
  .story a,
  .leadership-description,
  .organization-description,
  .detail {
    font-size: 0.875rem !important;
  }
  .name {
    font-size: 1rem !important;
  }
}
